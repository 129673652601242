<template>
  <div :style="`width : ${width}`">

    <div v-if="label" class="d-flex justify-space-between align-center mb-2">
      <h5 style="color : #4F5A6E; font-size: 13px; font-weight: 500 " >  {{  label  }} </h5>
      <ws-tooltip v-if="tooltip" :text="tooltip">
        <v-icon :color="wsDARKLIGHT">mdi-information-outline</v-icon>
      </ws-tooltip>
    </div>

    <v-select
        v-if="!autocomplete"
        v-model="text"
        :items="items"
        @keypress="handleKeyPress( $event,text )"
        @focusin="focused = true"
        @blur="focused = false ; $emit('change',text)"
        :class="{
          activeText : focused && !error,
          notActive : !focused && !error,
          errorStyle : error,
          avalon : avalonStyle
        }"
        class="textStyle mt-0"
        :placeholder="!text ? placeholder : ''"
        :color="wsACCENT"
        :type="type"
        dense
        :outlined="!flat"
        :hide-details="hideDetails"
        :error="error"
        :error-messages="errorMessages"
        :height="height"
        :counter="counter"
        :clearable="clearable"
        :prepend-inner-icon="prependInnerIcon"
        :append-icon="appendIcon"
        :disabled="disabled"
        :multiple="multiple"
        :background-color="backgroundColor || null"
        :readonly="readonly"
        :flat="flat"
        :solo="solo"


    >
      <template #item="{item}">
        <div :class="[{'justify-space-between' : item.right_icon}]" class="fill-height d-flex align-center" style="width : 100%">
          <v-icon :color="wsACCENT"  v-if="item.icon"  class="mr-3"> {{ item.icon }}</v-icon>
          <h5 class="font-weight-regular" :style="`color : ${wsACCENT}`">{{ item.text }}</h5>
          <v-icon v-if="item.right_icon"  :color="wsACCENT" class="mr-3"> {{ item.right_icon }}</v-icon>
        </div>

      </template>
    </v-select>
    <v-autocomplete
        v-else
        v-model="text"
        :items="selectItems"
        @keypress="handleKeyPress( $event,text )"
        @focusin="focused = true;"
        @blur="focused = false ; $emit('change',text)"
        :class="{
          activeText : focused && !error,
          notActive : !focused && !error,
          errorStyle : error,
          avalon : avalonStyle
        }"
        class="textStyle mt-0"
        :placeholder="placeholder"
        :color="wsACCENT"
        :type="type"
        dense
        :outlined="!flat"
        :hide-details="!counter"
        :error="error"
        :height="height"
        :counter="counter"
        :clearable="clearable"
        :multiple="multiple"
        :flat="flat"
        :solo="solo"
        ref="autocomplete"
        @input="handleChange"
        auto-select-first
    >


      <template #item="{item}">
          <h5 v-if="checkNewData(item)" :style="`color : ${wsACCENT}`">
            {{ item.value.includes('new-data::') ? $t(newDataText || 'Add') : '' }} "{{ item.text }}"
          </h5>
          <h5 v-else   :style="`color : ${wsACCENT}`"> {{ item.text }} </h5>
      </template>

      <template v-if="allowNewData" #no-data >
        <v-hover #default="{hover}">
          <v-sheet @click="handleNewData" class="px-4 py-3 pointer" v-ripple :color="!hover ? 'transparent' : '#00000011'">
            <h5 :style="`color : ${wsACCENT}`">
              <v-icon :color="wsACCENT">mdi-plus</v-icon>
              {{ $t(newDataText || 'Add') }} "{{ getSearchQuery() }}"
            </h5>
          </v-sheet>
        </v-hover>

      </template>

    </v-autocomplete>
    <v-divider class="mt-n5" v-if="avalonStyle"  :style="`border-color : ${wsDARKLIGHT}`" />
    <!-- Description-->
    <h5 v-if="description" :class="[{'mt-n4' : !autocomplete} , {'mt-2' : autocomplete} ]" class="font-weight-medium " :style="`color: ${wsDARKLIGHT}`">{{ $t(description) }}</h5>

  </div>
</template>

<script>
export default {
  name: "wsTextField",
  props : {
    value : {
      type : [ String , Array , Number ]
    },
    items : {
      type : Array,
      default() { return [] }
    },
    allowNewData : {
      type : Boolean,
      default : false
    },
    newDataText : {
      type : String
    },
    placeholder : {
      type : String,
    },
    label : {
      type : String,
    },
    tooltip : {
      type : String,
    },
    description : {
      type : String,
    },
    number : {
      type : Boolean,
      default : false
    },
    numberLength : {
      type : String,
      default : '10000000',
    },
    error : {
      type : Boolean,
      default : false
    },
    errorMessages : {
      type : [String, Array]
    },
    autocomplete : {
      type : Boolean,
      default : false
    },
    counter : {
      type : String,
    },
    height : {
      type : String,
    },
    clearable : {
      type : Boolean,
      default : false
    },
    width : {
      type :String,
      default : '100%'
    },
    prependInnerIcon : {
      type :String,
    },
    disabled : {
      type : Boolean,
      default : false
    },
    multiple : {
      type : Boolean,
      default : false
    },
    backgroundColor : {
      type :String,
    },
    hideDetails : {
      type : Boolean,
      default : false
    },
    readonly : {
      type : Boolean,
      default : false
    },
    appendIcon : {
      type : String
    },
    avalonStyle : {
      type : Boolean,
      dedfault : false
    },
    solo : {
      type : Boolean,
      default : false
    },
    flat : {
      type : Boolean,
      default : false
    },
  },
  watch : {
    value() {
      if ( this.value !== this.text ) {
        this.text = this.value
      }
    },
    text() {
      if ( this.value !== this.text ) {
        this.$emit('input',this.text)
      }
    },
    items : {
      handler() {
        if ( !this.EQ(this.items, this.selectItems) ) {
          this.selectItems = this.COPY(this.items)
        }
      },
      deep : true
    }
  },
  data() {
    return {
      text : '',
      focused : false,
      selectItems : [],
      isAutocompleteFocused : true
    }
  },
  computed : {
    type() {
      if ( this.number ) {
        return 'number'
      }
      return 'text'
    },
    createNewData() {
      if ( !this.value ) {
        return false
      }
      return this.value.toString().includes('new-data::')
    }
  },
  methods : {
    handleKeyPress($event , element ) {
      if ($event.charCode === 13 ) {

        if ( this.allowNewData && this.selectItems.findIndex(el => el.text === this.getSearchQuery()) === -1  ) {
          this.handleNewData()
        }
      }
      if ( this.number ) {
        this.NUMBER_INPUT($event,element, parseInt(this.numberLength) )
      }
    },
    getSearchQuery() {
      return this.$refs.autocomplete.internalSearch || '';
    },
    handleChange(value) {
      if ( !value ) {
        console.log('handleChange no value')
        this.selectItems = this.selectItems.filter( el => !(el.value.toString()).includes('new-data::'))
       return
      }
      if ( !value.toString().includes('new-data::')) {
        console.log('handleChange 2 new data no value')
        console.log(this.selectItems)
        this.selectItems = this.selectItems.filter( el => !(el.value.toString()).includes('new-data::'))
        console.log('i will be here ')

      }
    },
    handleNewData() {
      console.log('handleNewData value')
      console.log(this.selectItems)
      this.text = 'new-data::' + this.getSearchQuery()
     this.selectItems = this.selectItems.filter( el => !(el.value.toString()).includes('new-data::'))
      this.selectItems.unshift({ text : this.getSearchQuery() , value : 'new-data::' + this.getSearchQuery() , hidden : true })
      this.$emit('new-data' , this.getSearchQuery()  )
      this.$refs.autocomplete.isMenuActive = false ;
      this.$refs.autocomplete.blur()
    },
    checkNewData(item) {
      if ( !item.value) {
        return false
      }
      return item.value.toString().includes('new-data::')
    }
  },
  mounted() {
    this.selectItems = this.COPY(this.items)
    if ( this.avalonStyle ) {
      this.solo = true
      this.flat = true
    }

    this.text = this.value
  }
}
</script>

<style lang="css" scoped>
/*.v-text-field--outlined >>> fieldset {*/
/*  border-color: rgba(192, 0, 250, 0.986);*/
/*}*/

.v-select__slot {
  margin-left: -16px !important;
}
.avalon.v-input >>> div   {
  padding: 0 !important;
}
.notActive.v-text-field--outlined >>> fieldset {
  border-color: #9AB2C4 !important;
}
.activeText.v-text-field--outlined >>> fieldset {
  border-color: #567186 !important;
  border-width: 2px;
}
.notActive.v-text-field--outlined >>> input::placeholder {
  color: #9AB2C4 !important;
}
.notActive.v-text-field--outlined >>> textarea::placeholder {
  color: #9AB2C4 !important;
}
.errorStyle.v-text-field--outlined >>> input::placeholder {
  border-color: darkred !important;
  border-width: 2px;
}
.textStyle {
  font-weight: 500;
  font-size: 14px;
}



</style>